import { render, staticRenderFns } from "./ShareButton.vue?vue&type=template&id=48a6b3f6&scoped=true"
import script from "./ShareButton.vue?vue&type=script&lang=js"
export * from "./ShareButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a6b3f6",
  null
  
)

export default component.exports