<template>
    <div>
        <div v-if="visible">
            <h2>{{ $t('confirmation.share.title') }}</h2>
            <p>{{
                    $t('confirmation.share.subtitle', [$parent.$parent.settings.name, $parent.$parent.settings.name])
                }}</p>

            <button @click="triggerShare" class="button button-primary branding-button-background">
                {{ $t('confirmation.share.button') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
        };
    },
    mounted() {
        if (navigator.share) {
            this.visible = true;
        }
    },

    methods: {
        async triggerShare() {
            let shareURL = this.$parent.$parent.settings.shop_url;
            // Parse URL to build query string
            const url = new URL(shareURL);
            const params = new URLSearchParams(url.search);

            // https://support.google.com/analytics/answer/10917952
            params.append('utm_source', 'widget');
            params.append('utm_medium', 'share');
            params.append('utm_campaign', 'wshare');
            url.search = params.toString();
            shareURL = url.toString();

            const shareData = {
                text: this.$t('confirmation.share.content', [this.$parent.$parent.settings.name, shareURL]),
            };

            try {
                await navigator.share(shareData);
            } catch (err) {
                console.warn('Error: ' + err);
            }
        }
    }
};
</script>

<style scoped>

</style>
