// src/plugins/FacebookPixelPlugin.js

import BasePlugin from './BasePlugin';

export default class FacebookPixelPlugin extends BasePlugin {
    constructor(debug) {
        super(debug);
        this.trackingKey = 'gifty.fbq_last';
    }

    initialize() {
        if (typeof fbq === 'undefined') {
            this.debug.log('error', 'Facebook Pixel (fbq) is not defined');
            return false;
        }
        this.debug.log('fbq', 'Facebook Pixel plugin initialized');
        return true;
    }

    clearPreviousTracking() {
        sessionStorage.removeItem(this.trackingKey);
        this.debug.log('fbq', 'Cleared previous Facebook Pixel tracking data');
    }

    onShopViewed() {
        fbq('track', 'ViewContent', {
            currency: 'EUR',
            content_category: 'Gift Cards',
        });
        this.debug.log('fbq', 'ViewContent event fired');
    }

    onProductAdded(product) {
        if (product === null) {
            this.debug.log('fbq', 'Product is null, aborting Facebook Pixel event');
            return;
        }

        fbq('track', 'AddToCart', {
            value: product.price / 100,
            currency: 'EUR',
            content_ids: [product.sku],
            content_name: product.name,
            content_type: 'product',
            contents: [{ id: product.sku, quantity: product.quantity }]
        });

        this.debug.log('fbq', 'AddToCart event fired');
    }

    onOrderConfirmation(order) {
        if (order === null) {
            this.debug.log('fbq', 'Order is null, aborting tracking events');
            return;
        }

        if (sessionStorage.getItem('gifty.fbq_last') === order.order_id) {
            this.debug.log('fbq', 'Order already tracked, skipping');
            return;
        }

        let orderTotal = 0;
        let numItems = 0;
        let contents = [];

        order.giftcards.forEach((giftcard) => {
            let sku = giftcard.title
                ? `package-${giftcard.packageId}-${this.$root.settings.company_id}-${giftcard.value}`
                : `gift-card-${this.$root.settings.company_id}-${giftcard.value}`;

            contents.push({ id: sku, quantity: giftcard.quantity });
            numItems += giftcard.quantity;
            orderTotal += giftcard.value * giftcard.quantity;
        });

        fbq('track', 'Purchase', {
            value: orderTotal / 100,
            currency: 'EUR',
            content_type: 'product',
            contents: contents,
            num_items: numItems,
        });

        sessionStorage.setItem('gifty.fbq_last', order.order_id);
        this.debug.log('fbq', 'Purchase event fired');
    }
}
