export class DeliveryEstimates {
    constructor(deliveryData) {
        this.deliveryData = deliveryData;
    }

    getEstimateForMethod(countryCode, method) {
        const deliveryMethod = this.deliveryData.options[countryCode].find(option => option.method === method);
        if (!deliveryMethod) return null;
        const currentUtc = Date.now()

        for (const estimate of deliveryMethod.estimates) {
            const validFrom = Date.parse(estimate.valid_from);
            const validUntil = Date.parse(estimate.valid_until);

            if (currentUtc >= validFrom && currentUtc < validUntil) {
                return {
                    ...estimate,
                    delivery_date: this.addDaysToDate(currentUtc, estimate.delivery_days),
                    fulfilment_date: this.addDaysToDate(currentUtc, estimate.fulfilment_days)
                };
            }
        }

        return null;
    }

    addDaysToDate(baseDate, deliveryDays) {
        const date = new Date(baseDate);
        date.setDate(date.getDate() + deliveryDays);
        return date;
    }

    dateToDayLabel(inputDate) {
        const date = new Date(inputDate);
        const today = new Date();
        const tomorrow = this.addDaysToDate(today, 1);

        if (this.isSameDay(date, today)) return 'today';
        if (this.isSameDay(date, tomorrow)) return 'tomorrow';

        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const dayOfWeek = days[date.getDay()];
        const dayCountFromToday = Math.floor((date - today) / (1000 * 60 * 60 * 24));
        let dayPrefix = '';

        if(dayCountFromToday >= 7) {
            dayPrefix = 'next_'
        }

        return dayPrefix + dayOfWeek;
    }

    isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    getDeliveryTimeKey(method) {
        if (method === 'evening' || method === 'same-day') {
            return 'deliveryEstimateEvening';
        }
        return 'deliveryEstimate';
    }

    getDeliveryTimeI18nKey(country = 'NL', method = 'standard') {
        const estimate = this.getEstimateForMethod(country, method);

        return `intro.${this.getDeliveryTimeKey(method)}.${this.dateToDayLabel(estimate.delivery_date)}`;
    }

    getDeliveryDayEstimateI18nKey(country = 'NL', method = 'standard') {
        const estimate = this.getEstimateForMethod(country, method);

        return `intro.dayEstimate.${this.dateToDayLabel(estimate.delivery_date)}`;
    }
}
