<template>
    <div @click="openWidget"
         :class="['message', settings.position]"
         :style="{background: settings.toggle_color, color: settings.toggle_foreground_color}">
        {{ settings.notification_message }}
    </div>
</template>

<script>

// Default amount of seconds before displaying the toast
const DEFAULT_TOAST_TIMER = 1;
const DEFAULT_DISSAPEAR_TIMER = 6;
const DISSAPEAR_AFTER_X_SECONDS = true;

export default {
    data() {
        return {
            toastTimer: null, // In seconds
            showToastMessage: false,
        };
    },
    props: {
        settings: {
            required: true,
            type: Object,
        },
    },
    computed: {
        showToast() {
            return this.toastTimer <= 0 &&
                !!this.settings.notification &&
                this.showToastMessage;
        },
    },
    watch: {
        showToast(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('toggle', newValue);
            }
        },
    },
    mounted() {
        if (this.settings.notification) {
            this.resetToastTimer();
        }
    },
    methods: {
        openWidget() {
            window.parent.Gifty.EventBus.$emit('widget-open');
        },
        resetToastTimer(seconds) {
            this.toastTimer = seconds || DEFAULT_TOAST_TIMER;

            this.loadToastTimer();
        },

        loadToastTimer(dissapear = DISSAPEAR_AFTER_X_SECONDS) {
            this.showToastMessage = false;

            const toastTimer = setInterval(() => {
                this.toastTimer -= 1;

                if (this.toastTimer < 0) {
                    this.showToastMessage = true;
                    clearInterval(toastTimer);

                    if (dissapear) {
                        let dissapearTimerValue = DEFAULT_DISSAPEAR_TIMER;

                        const dissapearTimer = setInterval(() => {
                            dissapearTimerValue -= 1;
                            if (dissapearTimerValue < 0) {
                                clearInterval(dissapearTimer);
                                this.showToastMessage = false;
                                this.toastTimer = DEFAULT_TOAST_TIMER;
                            }
                        }, 1000);
                    }
                }
            }, 1000);
        },
    },
};
</script>
