// src/plugins/BasePlugin.js

export default class BasePlugin {
    constructor(debug) {
        this.debug = debug;
    }

    initialize() {
        throw new Error('initialize method must be implemented');
    }

    onShopViewed() {}
    onProductAdded(product) {}
    onOrderConfirmation(order) {}
}
