// src/plugins/GoogleTagManagerPlugin.js

import BasePlugin from './BasePlugin';

export default class GoogleTagManagerPlugin extends BasePlugin {
    constructor(debug) {
        super(debug);
        this.trackingKey = 'gifty.gtag_last';
    }

    initialize() {
        if (typeof gtag === 'undefined' && typeof dataLayer !== 'undefined') {
            window.gtag = function gtag() {
                dataLayer.push(arguments);
            };
            this.debug.log('gtm', 'Initialized gtag function');
        }

        if (typeof gtag === 'undefined') {
            this.debug.log('info', 'GTM: gtag is not defined, aborting tracking');
            return false;
        }

        this.debug.log('gtm', 'Google Tag Manager plugin initialized');
        return true;
    }

    clearPreviousTracking() {
        sessionStorage.removeItem(this.trackingKey);
        this.debug.log('gtm', 'Cleared previous Google Tag Manager tracking data');
    }

    onOrderConfirmation(order) {
        if (order === null) {
            this.debug.log('gtm', 'Order is null, aborting tracking.', 'Generally you\'ll see this message if the page has multiple instances of the order module and the event was already handled.');
            return;
        }

        if (sessionStorage.getItem('gifty.gtag_last') === order.order_id) {
            this.debug.log('gtm', 'Order already tracked, skipping.', 'Generally you\'ll see this message if the page has multiple instances of the order module and the event was already handled.');
            return;
        }

        let orderTotal = order.giftcards.reduce((total, giftcard) =>
            total + giftcard.value * giftcard.quantity, 0);

        const purchaseEventData = {
            transaction_id: order.order_id,
            value: orderTotal / 100,
            tax: 0.00,
            shipping: 0.00,
            currency: 'EUR',
            items: order.giftcards.map((giftcard) => ({
                item_id: 'gifty-gift-card',
                item_name: 'Gifty Gift Card',
                item_variant: giftcard.title
                    ? `Package: ${giftcard.title} (${giftcard.package_id})`
                    : `Value: ${giftcard.value}`,
                price: giftcard.value / 100,
                quantity: giftcard.quantity,
            }))
        };

        this.debug.log('gtm', 'Sending purchase event', purchaseEventData);

        try {
            gtag('event', 'purchase', purchaseEventData);
            this.debug.log('gtm', 'Purchase event sent successfully');
            sessionStorage.setItem('gifty.gtag_last', order.order_id);
        } catch (error) {
            this.debug.log('error', 'GTM: Error occurred while sending purchase event', { error: error.message });
        }
    }
}
