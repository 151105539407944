<template>
    <div class="row order-item">
        <div class="col order-row">
            <div class="order-text">{{ orderText }}</div>
            <div class="order-value">{{order.value | money}}</div>
        </div>
        <div class="col quantity-input">
            <quantity-input v-model="order.quantity" @input="$emit('quantityChange')" @delete-row="$emit('delete', order.value)"/>
        </div>
    </div>
</template>

<script>
import QuantityInput from '../../elements/QuantityInput';

export default {
    props: {
        order: Object,
    },
    components: {
        QuantityInput,
    },
    computed: {
        orderText() {
            if (this.order.package_id === null) {
                return this.$t('giftcard');
            }
            return this.order.title;
        },
    },
};
</script>
