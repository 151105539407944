<template>
    <div id="confirmation" class="container">
        <div class="content">
            <top-bar :previous="false" @close-widget="$emit('close-widget')"></top-bar>

            <div v-if="confirmation.transaction_status > 30">
                <div class="form-group">
                    <h1><i class="icon-payment branding-color"></i>{{ $t('confirmation.failed.title') }}</h1>
                    <p>{{ $t('confirmation.failed.subtitle') }}</p>
                </div>

                <div class="form-group">
                    <button class="button" @click="retryOrder">{{ $t('button.retry') }}</button>
                </div>

                <p><a href="#" @click.prevent="reset">{{ $t('confirmation.anotherOrder') }}</a></p>
            </div>

            <div v-if="confirmation.transaction_status === 10">
                <div class="form-group">
                    <h1>
                        <div class="loading branding-color"></div>
                        {{ $t('confirmation.processing.title') }}
                    </h1>
                    <p>{{ $t('confirmation.processing.subtitle') }}</p>

                    <div class="form-group">
                        <button class="button" @click="$parent.isEmbedded ? reset() : toggleWidget()">{{
                                $parent.isEmbedded ? $t('confirmation.anotherOrder') : $t('close')
                            }}
                        </button>
                    </div>

                    <p v-if="!$parent.isEmbedded"><a href="#" @click.prevent="reset">{{
                            $t('confirmation.anotherOrder')
                        }}</a></p>
                </div>
            </div>

            <div v-if="confirmation.transaction_status === 20 || confirmation.transaction_status === 30">
                <div class="form-group" id="confirmation-animation-spacing">
                    <SentAnimationTruck v-if="confirmation.delivery"/>
                    <SentAnimation v-else/>

                    <span v-if="confirmation.transaction_status === 20">
                        <h1>{{ $t('confirmation.pending.title') }}</h1>
                        <p>{{ $t('confirmation.pending.subtitle') }}</p>
                    </span>

                    <span v-if="confirmation.transaction_status === 30">
                        <h1>{{ $t('confirmation.success.title') }}</h1>
                        <p>{{ $t('confirmation.success.subtitle') }}</p>
                    </span>
                </div>

                <share-button class="form-group" />

                <div v-if="$parent.isEmbedded" class="form-group">
                    <p><a href="#" @click.prevent="reset">{{ $t('confirmation.backToStart') }}</a></p>
                </div>
                <div v-else>
                    <p><a href="#" @click.prevent="reset">{{ $t('confirmation.anotherOrder') }}</a></p>
                </div>
            </div>
        </div>

        <div class="fixed-bottom">
            <a href="https://gifty.nl" target="_blank"><img src="../../../assets/img/logo.svg" width="60" height="24"
                                                            class="logo"/></a>
        </div>
    </div>
</template>

<script>
import SentAnimation from '../../elements/SentAnimation';
import SentAnimationTruck from '../../elements/SentAnimationTruck';
import ShareButton from '../../elements/ShareButton.vue';

export default {
    components: { SentAnimation, SentAnimationTruck, ShareButton },
    data() {
        return {
            confirmation: this.$parent.$data.confirmation,
            orderFromSession: {},
            order_id: this.$parent.$data.order.order_id,
        };
    },

    methods: {
        reset() {
            this.$emit('next-view');
        },
        toggleWidget() {
            window.parent.Gifty.EventBus.$emit('widget-toggle');
        },
        retryOrder() {
            if (this.orderFromSession === null) {
                this.$emit('set-view', 'order', false);
                return false;
            }
            this.$parent.$data.order = this.orderFromSession;

            this.$emit('set-view', 'payment', false, 'bank');
            this.$emit('gen-giftcard-prev');
            return true;
        },
        verifyStatus() {
            setTimeout(() => {
                window.Gifty.axios.get(`${process.env.API_URL}/order/${this.order_id}`)
                    .then(({ data }) => {
                        this.confirmation = data;

                        if (this.confirmation.transaction_status === 10) {
                            this.verifyStatus();
                        } else if (this.confirmation.transaction_status === null) {
                            this.confirmation.transaction_status = 404;
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 404) {
                                // Invalid order, remove
                                this.confirmation.transaction_status = 404;
                            }
                        }
                    });
            }, 3000);
        },
    },

    mounted() {
        this.orderFromSession = JSON.parse(sessionStorage.getItem('gifty.order'));

        switch (this.confirmation.transaction_status) {

            /**
             * Pending
             */
            case 0:
            case 10:
                if (window.parent.location.hash === '#gifty.order') {
                    this.retryOrder();
                } else {
                    this.verifyStatus();
                }
                break;
            /**
             * Failed
             */
            case 40:
            case 50:
            case 60:
            case 70:
                break;

            /**
             * All other
             */
            default:
                if (this.confirmation.transaction_status === null) {
                    this.confirmation.transaction_status = 404;
                }

                if (this.confirmation.transaction_status === 20 ||
                    this.confirmation.transaction_status === 30) {
                    window.parent.Gifty.EventBus.$emit('order-confirmation', this.orderFromSession);
                }

                sessionStorage.removeItem('gifty.order');
                break;
        }
    },
};
</script>
