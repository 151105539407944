<template>
    <div id="gifty-container" :class="this.$root.settings.position">
        <!--<transition appear name="bounce" v-if="visible">-->
        <vue-iframe id="gifty-widget" v-if="visible" cssFile="app" :class="this.$root.settings.position">
            <widget :settings="this.$root.settings" ref="widget"></widget>
        </vue-iframe>
        <!--</transition>-->


        <transition appear name="slide-in" v-if="showToggle">
            <div id="gifty-toggle-container" :class="this.$root.settings.position"
                 :style="{background: this.$root.settings.toggle_color}">
                <vue-iframe id="gifty-toggle" cssFile="toggle">
                    <button id="toggle" @click="toggleWidget" :style="{background: this.$root.settings.toggle_color}">
                        <i class="icon-giftcard" :style="{color: this.$root.settings.toggle_foreground_color}"></i>
                    </button>
                </vue-iframe>
            </div>
        </transition>

        <transition appear name="slide-in" v-if="showToggle">
            <div id="gifty-toast-container" v-show="showToast" :class="this.$root.settings.position">
                <vue-iframe id="gifty-toast" cssFile="toast">
                    <Toast @toggle="toggleToast" :settings="this.$root.settings"/>
                </vue-iframe>
            </div>
        </transition>
    </div>
</template>

<script>
import VueIframe from './components/VueIframe';
import Widget from './components/Widget';
import Toast from './components/Toast';
import FacebookPixelPlugin from './plugins/FacebookPixelPlugin';
import GoogleTagManagerPlugin from './plugins/GoogleTagManagerPlugin';

export default {
    data() {
        return {
            visible: false,
            showToast: false,
            plugins: [],
        };
    },

    components: {
        VueIframe,
        Widget,
        Toast,
    },

    methods: {
        toggleWidget() {
            window.Gifty.EventBus.$emit('widget-toggle');
        },
        setVisible(value) {
            window.Gifty.stateString = value ? 'open' : 'closed';
            this.visible = value;
        },
        toggleToast(newValue = !this.showToast) {
            this.showToast = newValue;
        },
    },

    computed: {
        showToggle() {
            if (typeof window.Gifty.trigger === 'undefined') {
                return true;
            }
            return window.Gifty.trigger;
        },
    },

    async beforeMount() {
        const debug = window.Gifty.debug;

        // Check for active order ID
        const orderId = localStorage.getItem('gifty.activeOrder');

        if (orderId !== null) {
            debug.log('info', 'Active order ID found', { orderId });
            this.$root.settings.order_id = orderId;
        }

        // Initialize plugins
        if (window.Gifty.fbq === true) {
            const fbPixelPlugin = new FacebookPixelPlugin(debug);
            if (fbPixelPlugin.initialize()) {
                this.plugins.push(fbPixelPlugin);
            }
        } else {
            debug.log('info', 'Facebook Pixel tracking is not active');
        }

        if (window.Gifty.gtag === true) {
            const gtmPlugin = new GoogleTagManagerPlugin(debug);
            if (gtmPlugin.initialize()) {
                this.plugins.push(gtmPlugin);
            }
        } else {
            debug.log('info', 'Google Tag Manager tracking is not active');
        }

        // Setup test confirmation if needed
        await debug.setupTestConfirmation(this.plugins);
    },

    mounted() {
        const debug = window.Gifty.debug;

        // Set up event listeners
        window.Gifty.EventBus.$on('widget-toggle', () => {
            this.setVisible(!this.visible);
            debug.log('event', `Widget toggled: ${this.visible ? 'opened' : 'closed'}`);
        });

        window.Gifty.EventBus.$on('widget-open', () => {
            this.setVisible(true);
            debug.log('event', 'Widget opened');
        });

        window.Gifty.EventBus.$on('widget-close', () => {
            this.setVisible(false);
            debug.log('event', 'Widget closed');
        });

        window.Gifty.EventBus.$on('add-to-cart', async (product) => {
            this.setVisible(true);
            debug.log('event', 'Add to cart triggered', product);
            await this.$nextTick();
            window.Gifty.EventBus.$emit('internal-add-to-cart', product);
        });

        window.Gifty.EventBus.$on('shop-viewed', () => {
            this.plugins.forEach(plugin => plugin.onShopViewed());
        });

        window.Gifty.EventBus.$on('product-added', (product) => {
            this.plugins.forEach(plugin => plugin.onProductAdded(product));
        });

        window.Gifty.EventBus.$on('order-confirmation', (order) => {
            this.plugins.forEach(plugin => plugin.onOrderConfirmation(order));
        });

        if (this.$root.settings.order_id) {
            this.setVisible(true);
        }
    },
};
</script>
