<template>
    <div class="quantity-group">
        <button class="quantity-decrease" @click="decrementQuantity">
            <i v-if="quantity > 1" class="icon-minus"></i>
            <i v-else class="icon-bin"></i>
        </button>
        <input type="number" class="quantity-input" ref="input" min="1"
               v-bind:value="quantity"
               v-on:input="updateValue($event.target.value)"/>
        <button class="quantity-increase" @click="incrementQuantity"><i class="icon-plus"></i></button>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            value: Number,
            default: 1,
        },
    },
    data() {
        return {
            quantity: this.value,
        };
    },
    methods: {
        incrementQuantity() {
            this.quantity += 1;

            // Emit the number value through the input event
            this.$emit('input', this.quantity);
        },

        decrementQuantity() {
            this.quantity -= 1;

            if (this.quantity <= 0) {
                this.$emit('delete-row');
                return true;
            }

            // Emit the number value through the input event
            this.$emit('input', this.quantity);
            return true;
        },

        updateValue(value) {
            this.quantity = Number(value);

            // Emit the number value through the input event
            this.$emit('input', this.quantity);
        },
    },
    watch: {
        value(val) {
            this.quantity = val;
        },
    },
};
</script>
