<template>
    <div class="container">
        <div class="content">
            <top-bar @close-widget="$emit('close-widget')"
                     :previous="false"></top-bar>
            <div class="payment-warning">
                <img class="warning-icon" src="../../../assets/img/set.svg">
                <h1>{{ $t('payment.warning.title') }}</h1>
                <p class="warning-text" v-html="$t('payment.warning.subtitle', [name])"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'name',
    ],
};
</script>
