<template>
    <div id="terms_conditions" class="container" ref="terms_conditions">
        <div class="content">
            <top-bar @prev-view="$emit('set-view', 'payment')"
                     @close-widget="$emit('set-view', 'payment')"></top-bar>

            <h1>Algemene voorwaarden en privacy statement</h1>
            <p>Onze algemene voorwaarden en ons privacy statement zijn op deze pagina te lezen en te downloaden.</p>
            <ul>
                <li>
                    <a href="https://gifty.nl/algemene-voorwaarden.pdf" target="_blank" rel="noreferrer">
                        Algemene voorwaarden (PDF)
                    </a>
                </li>

                <li v-if="typeof $parent.settings.additional_terms !== 'undefined'">
                    <a :href="$parent.settings.additional_terms" target="_blank" rel="noreferrer">
                        Aanvullende algemene voorwaarden (PDF)
                    </a>
                </li>

                <li>
                    <a href="https://gifty.nl/privacy-statement.pdf" target="_blank" rel="noreferrer">
                        Privacy statement (PDF)
                    </a>
                </li>
            </ul>
        </div>

        <div class="fixed-bottom">
            <button class="button button-primary branding-button-background" @click="accept">
                {{ $t('payment.accept') }}
                <i class="icon-arrow_right"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            order: this.$parent.$data.order,
        };
    },

    methods: {
        accept() {
            this.order.terms_conditions = true;
            this.$emit('set-view', 'payment', false, 'terms_conditions');
        },
    },

    mounted() {
        if (this.$parent.$data.view_ref) {
            this.$nextTick(() => {
                this.$parent.$refs.scrollable.scrollTop = this.$refs[this.$parent.$data.view_ref]
                    .offsetTop;

                this.$parent.$data.view_ref = false;
            });
        } else {
            this.$parent.$refs.scrollable.scrollTop = 0;
        }
    },
};
</script>
