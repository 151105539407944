<template>
    <div id="topbar">
        <i @click="$emit('prev-view')" class="icon-arrow_left" v-if="previous"></i>
        <i @click="toggleWidget" class="icon-close" v-if="close && !$parent.$parent.isEmbedded"></i>
    </div>
</template>

<script>
export default {
    props: {
        previous: {
            type: Boolean,
            default: true,
        },
        close: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        toggleWidget() {
            window.parent.Gifty.EventBus.$emit('widget-toggle');
        },
    },
};
</script>
