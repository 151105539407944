import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueScrollTo from 'vue-scrollto';
import Vue2TouchEvents from 'vue2-touch-events';
import { VueHammer } from 'vue2-hammer';
import App from './App';
import TopBar from './components/elements/TopBar';
import Nextviewbutton from './components/elements/NextViewButton';
import Embedded from './utils/Embedded';
import translations from './locales';
import createDebugTools from './debugTools'; // Import the debug tools

if (window.Gifty === undefined) {
    window.Gifty = {};
}
window.Gifty.debug = createDebugTools(window.Gifty);

Vue.use(Vue2TouchEvents);
Vue.use(VueScrollTo);
Vue.use(VueI18n);
Vue.use(VueHammer);
Vue.component('next-view-button', Nextviewbutton);
Vue.component('top-bar', TopBar);
Vue.prototype.$money = (value, useSymbol = true) => {
    if (value === 0) {
        return window.Gifty.app.$t('free');
    }
    const cents = value / 100;
    const calculated = cents.toFixed(2)
        .replace('.', ',');
    if (useSymbol) {
        return `€\u00A0${calculated}`;
    }
    return calculated;
};
Vue.filter('money', Vue.prototype.$money);

window.Gifty.open = () => {
    window.Gifty.EventBus.$emit('widget-open');
};
window.Gifty.close = () => {
    window.Gifty.EventBus.$emit('widget-close');
};
window.Gifty.toggle = () => {
    window.Gifty.EventBus.$emit('widget-toggle');
};
window.Gifty.state = function state() {
    return typeof window.Gifty.stateString === 'undefined' ? 'closed' : window.Gifty.stateString;
};
window.Gifty.logTestData = (data) => {
    window.Gifty.EventBus.$emit('log-test-data');
};
window.Gifty.setTestData = (data) => {
    window.Gifty.EventBus.$emit('set-test-data', data);
};
window.Gifty.addToCart = (product) => {
    window.Gifty.EventBus.$emit('add-to-cart', product);
};
window.Gifty.applyActionCode = (code) => {
    window.Gifty.EventBus.$emit('apply-action-code', code);
}

window.Gifty.EventBus = new Vue();
window.Gifty.axios = require('axios');

window.Gifty.debug.log('info', 'Global methods and EventBus initialized');

function getFirstBrowserLanguage() {
    const nav = window.navigator;
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
    let i;
    let language;

    // Check for overwritten language
    if (window.Gifty.language) {
        if (['en'].includes(window.Gifty.language)) {
            return 'en-GB';
        }

        if (['de'].includes(window.Gifty.language)) {
            return 'de-DE';
        }

        if (['fr'].includes(window.Gifty.language)) {
            return 'fr-FR';
        }

        if (['nl'].includes(window.Gifty.language)) {
            return 'nl-NL';
        }
    }

    // Support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i += 1) {
            language = nav.languages[i];
            if (language && language.length) {
                return language;
            }
        }
    }

    // Support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i += 1) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return language;
        }
    }

    return 'en-GB';
}

window.Gifty.locale = getFirstBrowserLanguage();
window.Gifty.axios.defaults.headers.common['Accept-Language'] = getFirstBrowserLanguage();

window.Gifty.debug.log('info', `Locale set to: ${window.Gifty.locale}`);

export const i18n = new VueI18n({
    locale: getFirstBrowserLanguage(),
    fallbackLocale: 'en-GB',
    messages: translations,
});

window.Gifty.setKey = (wkey) => {
    if (!wkey) {
        window.Gifty.debug.log('error', 'Method setKey called with invalid key');
        return;
    }

    window.Gifty.key = wkey;
    window.Gifty.debug.log('info', `Widget initializing with key: ${wkey}`);

    // Remove toggle widget
    if (window.document.contains(window.document.getElementById('gifty-container'))) {
        window.document
            .getElementById('gifty-container')
            .remove();
    }

    // Remove embedded widget
    if (window.document.contains(window.document.getElementById('gifty-embedded'))) {
        const current = window.document.getElementById('gifty-embedded');
        const parent = current.parentNode;

        const placeholder = document.createElement('div');
        placeholder.dataset.gifty = '';

        parent.replaceChild(placeholder, current);
    }

    window.Gifty.app = new Vue({
        template: '<App />',
        components: { App, TopBar },
        data: {
            settings: {},
            embedded: Embedded,
        },
        i18n,
        watch: {
            async settings(value) {
                this.mount();
                this.embedded.init(value);
                window.Gifty.debug.log('info', 'Settings updated, widget mounted and embedded initialized');
            },
        },

        beforeCreate() {
            const url = `${process.env.API_URL}/widget/${window.Gifty.key}?locale=${i18n.locale}&version=${process.env.VERSION}`;
            window.Gifty.axios.get(url)
                .then(({ data }) => {
                    this.settings = data.data;
                })
                .catch((error) => {
                    window.Gifty.debug.log('error', 'Failed to fetch widget data', error);
                    console.error('[Gifty] The embed code is not found or recognized');
                });
        },

        methods: {
            mount() {
                if (this.settings['translations']) {
                    // Overwrite with server-side translations
                    Object.keys(this.settings['translations'])
                        .forEach((locale) => {
                            i18n.mergeLocaleMessage(locale, this.settings['translations'][locale]);
                        });
                    window.Gifty.debug.log('info', 'Server-side translations merged');
                }

                const el = {
                    container: window.document.createElement('div'),
                    style: window.document.createElement('style'),
                    css: require('./assets/style/iframe.scss'),
                };

                const fpPromise = FingerprintJS.load();

                (async () => {
                    try {
                        const fp = await fpPromise;
                        const result = await fp.get();
                        window.Gifty.axios.defaults.headers.common['X-Fraud-Identity'] = result.visitorId;
                        window.Gifty.debug.log('info', 'Fingerprint generated and set in headers');
                    } catch (error) {
                        window.Gifty.debug.log('error', 'Failed to generate fingerprint', error);
                    }
                })();

                // Add stylesheets if not available yet
                if (window.document.contains(window.document.getElementById('gifty-stylesheet')) === false) {
                    // el.style.innerHTML = el.css.toString();
                    el.style.innerHTML = el.css.toString();
                    el.style.setAttribute('id', 'gifty-stylesheet');
                    window.document.head.appendChild(el.style);
                }

                window.document.body.appendChild(el.container);

                this.$mount(el.container);
            },
        },
    });
};

window.Gifty.setKey(window.Gifty.key);

window.Gifty.debug.log('info', 'Application instantiation complete');

export default window.Gifty;
