<template>
    <div>
        <div class="counter-group">
            <label>
                <slot></slot>
            </label>
            <span>{{ $t('personalize.characters', [counter]) }}</span>
        </div>
        <div class="input-group">
		<textarea
            ref="text"
            required
            rows="4"
            :maxlength="limit"
            :value="value"
            :placeholder="placeholder"
            @input="updateValue"
            @keydown="denyEmoji"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: String,
        first: {
            type: Boolean,
            default: () => true,
        },
        limit: {
            type: Number,
            default: 300,
        },
    },
    computed: {
        usedPoints() {
            let points = this.value.length;

            const amountOfEnters = this.value.match(/\n/g);
            if (amountOfEnters) {
                points += amountOfEnters.length * 15;
            }

            return points;
        },
        counter() {
            return this.limit - this.usedPoints;
        },
    },
    methods: {
        /**
         * @param { InputEvent } event
         */
        updateValue(event) {
            // Characters to allow
            // ! = ASCI33, ~ = ASCI126,  0-9, A-Z en a-Z zitten daartussen. http://www.asciitable.com/
            const regExp = /[^\s!-~]/g;

            event.target.value = event.target.value.replace(regExp, '');

            this.$emit('input', event.target.value);
        },

        /**
         *
         * @param {KeyboardEvent}event
         */
        denyEmoji(event) {
            if (event.key === '' ||
                event.key === 'Unidentified' ||
                event.code === 'Unidentified' ||
                event.keyIdentifier === 'Unidentified') {
                event.preventDefault();
            }

            const isRemovingCharacters = event.key === 'Backspace' || event.key === 'Delete';
            if (!isRemovingCharacters && this.counter <= 0) {
                event.preventDefault();
            }
        },
    },
    mounted() {
        if (this.first) {
            this.$nextTick(() => {
                this.$refs.text.focus();
            });
        }
    },
};
</script>
