<template>
    <div id="slider" v-if="show">
        <div class="image-slider" ref="imgSlider" :style="{ width: cardWidth+'px' }">
            <img src="../../assets/img/close.svg" class="close-icon" @click="$emit('close')" width="25" height="26">
            <transition name="fade-next-button">
                <div class="next-button"
                     @click="nextSlide"
                     v-if="Math.abs(sliderLeft) < maxPositionLeft">
                    <i class="branding-color icon-arrow_right"></i>
                </div>
            </transition>
            <transition name="fade-prev-button">

                <div class="prev-button"
                     @click="prevSlide"
                     v-if="sliderLeft < 0">
                    <i class="branding-color icon-arrow_left"></i>
                </div>
            </transition>

            <div class="slider-group" v-if="images !== undefined" :style="{ width: totalWidth+'px', left: sliderLeft+'px' }">
                <div class="img-slide"
                     v-hammer:pan="() => {}"
                     v-hammer:panstart="(event) => pan(event)"
                >
                    <div class="img-box" :style="{ width: cardWidth+'px' }">
                        <img class="slide" :src="makeImageFromBase64(images.front)"/>
                    </div>
                </div>
                <div class="img-slide"
                    v-if="images.back"
                    v-for="(img, index) in images.back"
                    :key="index"
                    v-hammer:pan="() => {}"
                    v-hammer:panstart="(event) => pan(event)"
                >
                    <div class="img-box" :style="{ width: cardWidth+'px' }">
                        <img class="slide" :src="makeImageFromBase64(img)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-dots" v-if="images !== undefined">
            <ul class="dots">
                <li class="dot" :class="{ 'not-active': isNotActive(0)}">
                    <button class="branding-background branding-border" @click="changeStep(0)"></button>
                </li>
                <li class="dot" v-for="(img, index) in images.back" :class="{ 'not-active': isNotActive(index + 1)}"
                    :key="index">
                    <button class="branding-background branding-border" @click="changeStep(index + 1)"></button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'images',
    ],
    data() {
        return {
            show: false,
            lastStep: 0,
            sliderWidth: 285,
            sliderLeft: 0,
            totalWidth: 0,
            cardWidth: 0,
        };
    },
    methods: {
        nextSlide() {
            if (Math.abs(this.sliderLeft) < this.maxPositionLeft) {
                this.sliderLeft = this.sliderLeft - this.cardWidth;
            }
        },
        prevSlide() {
            if (this.sliderLeft !== 0) {
                this.sliderLeft = this.sliderLeft + this.cardWidth;
            }
        },
        pan(event) {
            const direction = event.deltaX < 0 ? 'left' : 'right';
            if (direction === 'left') {
                this.nextSlide();
            } else {
                this.prevSlide();
            }
        },
        changeStep(step) {
            const left = this.cardWidth * step;
            this.sliderLeft = -Math.abs(left);
        },
        makeImageFromBase64(imageData) {
            return `data:image/png;base64,${imageData}`;
        },
        setSliderSettings() {
            this.sliderLeft = 0;
            this.sliderWidth = this.$parent.$refs.confirmTable.clientWidth;
            this.cardWidth = this.sliderWidth - 12;

            this.$nextTick(() => {
                if (this.images !== undefined) {
                    this.lastStep = this.images.back.length + 1;
                    this.setTotalWidth();
                    this.show = true;
                }
            });
        },
        setTotalWidth() {
            const total = this.lastStep;
            this.totalWidth = (this.cardWidth * total) + 4;
        },
        isNotActive(index) {
            const max = (this.cardWidth * index) + 1;
            const min = max - this.cardWidth;
            if (max > Math.abs(this.sliderLeft) && min <= Math.abs(this.sliderLeft)) {
                return false;
            }
            return true;
        },
    },
    computed: {
        maxPositionLeft() {
            const wrappings = this.lastStep - 1;
            const maxPosition = (wrappings * this.cardWidth);
            return maxPosition;
        },
    },
    watch: {
        images() {
            this.lastStep = this.images.back.length + 1;
            this.setSliderSettings();
        },
    },
    mounted() {
        this.setSliderSettings();
    },
};
</script>
