<template>
    <div id="payment-redirect" class="container">
        <div class="content">
            <top-bar @close-widget="$emit('close-widget')"
                     :previous="false"></top-bar>
            <div class="form-group">
                <h1><i class="icon-payment branding-color"></i>{{ $t('payment.redirect.title') }}</h1>
                <p class="warning-text" v-html="$t('payment.redirect.subtitle', [name])"></p>
            </div>

            <div class="form-group">
                <a :href="shopUrl" target="_parent" class="button button-primary branding-button-background">{{ $t('button.callToAction') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'name',
        'shopUrl',
    ],
    data() {
        return {
            settings: this.$parent.settings,
        };
    },
};
</script>
