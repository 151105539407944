import enGB from './en-GB.json';
import nlNL from './nl-NL.json';
import nlBE from './nl-BE.json';
import frBE from './fr-BE.json';
import frFR from './fr-FR.json';
import deDE from './de-DE.json';

export default {
    'en': enGB,
    'en-GB': enGB,
    'en-US': enGB,
    'nl': nlNL,
    'nl-NL': nlNL,
    'nl-BE': nlBE,
    'fr-BE': frBE,
    'fr': frFR,
    'fr-FR': frFR,
    'de': deDE,
    'de-DE': deDE,
};
