<template>
    <div>
        <div class="loading-box">
            <div class="form-group">
                <h3>
                    <div class="loading branding-color"></div>
                    {{ $t('payment.example') }}
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>
