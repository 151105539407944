<template>
    <div>
        <div class="form-group">
            <div class="amount-group">
                <div class="border">
                    <div class="amount-currency">€</div>
                    <input :type="inputtype" class="amount-input" v-model="amount"
                           :placeholder="(placeholder / 100)"
                           ref="amount" @keydown.enter="submitInput" @keydown="amountKeydown" @blur="reset"
                           @input="validateAmount"
                           :min="(settings.giftcard_min / 100)" :max="(settings.giftcard_max / 100)" required>
                    <div class="amount-seperator" v-if="centsVisible">,</div>
                    <input type="number" class="decimal-input" ref="cents" v-if="centsVisible" v-model="cents"
                           @keydown.enter="submitInput" @keydown="centsKeydown">
                    <span ref="input_calc_el" class="amount-calc">0</span>
                </div>
                <button class="button button-primary branding-button-background button-inline amount-submit" @click="submitInput"
                        :disabled="!viewValidates"><i class="icon-arrow_right"></i></button>
            </div>
        </div>

        <transition name="fade">
            <p class="text-notice" v-if="insufficientAmountMin || insufficientAmountMax">
                <span
                    v-if="insufficientAmountMin">{{ $t('orderpage.warning.min', [$money(settings.giftcard_min)]) }}</span>
                <span
                    v-if="insufficientAmountMax">{{ $t('orderpage.warning.max', [$money(settings.giftcard_max)]) }}</span>
            </p>
        </transition>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
    props: {
        value: { type: String, default: '' },
        placeholder: { type: Number, default: 0 },
    },
    computed: {
        internalValue: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            },
        },
        totalAmount() {
            return Number(this.amount * 100) + Number(this.cents);
        },
        viewValidates() {
            return this.totalAmount >= this.settings.giftcard_min &&
                this.totalAmount <= this.settings.giftcard_max;
        },
    },
    data() {
        return {
            amount: '',
            comma: false,
            cents: false,
            centsVisible: false,
            inputtype: 'number',
            settings: this.$parent.settings,
            insufficientAmountMin: false,
            insufficientAmountMax: false,
        };
    },
    mounted() {
        this.$refs.amount.focus();

        // Apply input style to the placeholder element for width calculation
        const elInput = this.$refs.amount;
        const elCalc = this.$refs.input_calc_el;

        const computedStyle = getComputedStyle(elInput);
        Array.from(computedStyle).forEach(
            key => elCalc.style.setProperty(
                key,
                computedStyle.getPropertyValue(key),
                computedStyle.getPropertyPriority(key),
            ));
        const newWidth = elCalc.getBoundingClientRect().width;
        elInput.style.width = newWidth;
        elInput.style.minWidth = newWidth;
    },
    watch: {
        amount(newValue) {
            const elInput = this.$refs.amount;
            const elCalc = this.$refs.input_calc_el;
            elCalc.innerText = newValue;
            const newWidth = elCalc.getBoundingClientRect().width;

            elInput.style.width = newWidth;
        },
    },
    methods: {
        validateAmount: debounce(function setInsufficientAmount() {
            this.insufficientAmountMin = false;
            this.insufficientAmountMax = false;
            if (this.totalAmount < this.settings.giftcard_min) {
                this.insufficientAmountMin = true;
            }
            if (this.totalAmount > this.settings.giftcard_max) {
                this.insufficientAmountMax = true;
            }
        }, 500),
        /**
         * Check if the key event is allowed as a basic action
         * Keys that we have nothing to do with and basic controls should all be allowed.
         * Think of function keys, arrow keys, backspace, pasting text etc
         * @param event
         */
        isKeyEventAllowedAsBasicAction(event) {
            // Allow basic control keys
            let allowedKeys = [
                'Backspace', 'Delete', 'Escape', 'Enter', 'Control', 'Meta', 'Alt', 'Shift',
                'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
                'End', 'Home', 'Insert',
                'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12',
            ];

            if (allowedKeys.indexOf(event.key) !== -1) {
                return true;
            }

            // Allow certain combinations for actions like copy, paste, cut, select all
            if ((event.ctrlKey || event.metaKey) && ['a', 'c', 'v', 'x'].indexOf(event.key) !== -1) {
                return true;
            }

            return false;
        },
        amountKeydown(event) {
            if(this.isKeyEventAllowedAsBasicAction(event)) {
                return;
            }

            // Go to the next input field on comma, period or tab
            let nextInputKeys = [',', '.', 'Tab'];
            if (nextInputKeys.indexOf(event.key) !== -1) {
                if (event.preventDefault) event.preventDefault();
                if (event.returnValue) event.returnValue = false;

                this.centsVisible = true;

                this.$nextTick(function setFocus() {
                    this.$refs.cents.focus();
                });

                return;
            }

            // Allow the input of numbers
            if (event.key >= 0 && event.key <= 9) {
                return;
            }

            // Prevent the default action
            if (event.preventDefault) event.preventDefault();
            if (event.returnValue) event.returnValue = false;
        },

        centsKeydown(event) {
            // Go back to the previous input field on backspace if the cents field is empty
            if (event.key === 'Backspace' && this.cents.length === 0) {
                if (event.preventDefault) event.preventDefault();
                if (event.returnValue) event.returnValue = false;

                this.centsVisible = false;

                this.$nextTick(function setFocus() {
                    this.$refs.amount.focus();
                });

                return;
            }

            if(this.isKeyEventAllowedAsBasicAction(event)) {
                return;
            }

            // Don't allow more than two digits in the cents field
            if (this.cents.length >= 2) {
                if (event.preventDefault) event.preventDefault();
                if (event.returnValue) event.returnValue = false;
            }

            // Allow the input of numbers
            if (event.key >= 0 && event.key <= 9) {
                return;
            }

            // Prevent the default action
            if (event.preventDefault) event.preventDefault();
            if (event.returnValue) event.returnValue = false;
        },

        reset() {
            // this.inputtype = 'number';
        },

        submitInput() {
            if (this.viewValidates) {
                this.$emit('submit', this.totalAmount);
            }
        },
    },
};
</script>
