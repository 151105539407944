<template>
    <div class="container" id="personalize">
        <div class="content">
            <top-bar @prev-view="$emit('prev-view')" @close-widget="$emit('close-widget')"></top-bar>

            <h1><i class="icon-giftcard branding-color"></i>{{ $t('personalize.title') }}</h1>
            <p>{{ $t('personalize.subtitle') }}</p>

            <div class="form-group">
                <div class="card-group card-arrow">
                    <div class="card" @click="setPersonalize('no')"
                         :class="{'card-selected': order.personalize === 'no'}">
                        <div class="card-button">
                            <i class="icon-chat-empty branding-color"></i>
                            <p>
                                {{ $t('no') }}
                                <span v-if="personalizationCosts()">
                                    <br/><br/>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="card" @click="setPersonalize('yes')"
                         :class="{'card-selected': order.personalize === 'yes'}">
                        <div class="card-button">
                            <i class="icon-chat branding-color"></i>
                            <p v-if="totalGiftcards > 1">
                                {{ $t('personalize.option.general') }}<br/>
                                <small v-if="personalizationCosts()">
                                    {{personalizationCosts() | money}}
                                    <span v-if="personalizationSum()">{{ $t('each') }}</span>
                                </small>
                            </p>
                            <p v-else>
                                {{ $t('yes') }}<br/>
                                <small v-if="personalizationCosts()">
                                    {{personalizationCosts() | money}}
                                    <span v-if="personalizationSum()">{{ $t('each') }}</span>
                                </small>
                            </p>
                        </div>
                    </div>
                    <div v-if="totalGiftcards > 1" class="card" @click="setPersonalize('unique')"
                         :class="{'card-selected': order.personalize === 'unique'}">
                        <div class="card-button">
                            <i class="icon-chat-group branding-color"></i>
                            <p>
                                {{ $t('personalize.option.unique') }}<br/>
                                <small v-if="personalizationCosts()">
                                    {{personalizationCosts() | money}}
                                    <span v-if="personalizationSum()">{{ $t('each') }}</span>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div v-if="order.personalize === 'unique'" v-for="giftcard in order.giftcards">
                    <div class="unique" v-for="i in giftcard.quantity">
                        <counter-input v-model="giftcard.message[i]"
                                       :first="giftcard === order.giftcards[0] && i === 1"
                                       :placeholder="$t('personalize.helptext')">
                            <div v-if="giftcard.package_id === null">
                                {{ $t('giftcard') }} <strong>{{giftcard.value | money}}</strong>
                            </div>
                            <div v-else>{{ giftcard.title }}</div>
                        </counter-input>
                    </div>
                </div>

                <div v-if="order.personalize === 'yes'">
                    <counter-input v-model="message" :placeholder="$t('personalize.helptext')">
                        {{ $t('giftcard') }}
                    </counter-input>
                </div>
            </div>
        </div>

        <next-view-button :disabled="!viewValidates" field=".counter-group + .input-group textarea" :onClick="nextStep">
            {{ $t('button.deliveryMethod') }}
        </next-view-button>
    </div>
</template>

<script>
import CounterInput from '../elements/CounterInput';

export default {
    data() {
        return {
            message: '',
            order: this.$parent.order,
            settings: this.$parent.settings,
            totalGiftcards: this.$parent.totalGiftcards,
        };
    },

    components: {
        CounterInput,
    },

    computed: {
        viewValidates() {
            if (this.order.personalize === 'no') {
                return true;
            }

            if (this.order.personalize === 'unique' ||
                this.order.personalize === 'yes') {
                // Validate if at least one giftcard has a message set
                for (let i = 0; i < this.order.giftcards.length; i += 1) {
                    for (let message = 1; message <= this.order.giftcards[i].quantity;
                        message += 1) {
                        if (this.order.giftcards[i].message[message]) {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
    },

    methods: {
        setPersonalize(option) {
            this.order.personalize = option;

            if (option === 'no') {
                this.setMessageForAllGiftcards('');
            }
        },
        setMessageForAllGiftcards(message) {
            for (let index = 0; index < this.order.giftcards.length; index += 1) {
                const messages = [];

                for (let i = 1; i <= this.order.giftcards[index].quantity; i += 1) {
                    messages[i] = message;
                }

                this.$set(this.order.giftcards[index], 'message', messages);
            }
        },
        personalizationCosts() {
            if (this.settings.personalization.sum) {
                return this.settings.personalization.costs * this.order.giftcards.length;
            }

            return this.settings.personalization.costs;
        },
        personalizationSum() {
            return this.settings.personalization.sum;
        },
        nextStep() {
            this.$emit('next-view');
            this.$emit('gen-giftcard-prev');
        },
    },

    watch: {
        message(message) {
            this.setMessageForAllGiftcards(message);
        },
    },

    mounted() {
        /**
         * Order changed from multiple to single giftcard,
         * change personalize 'unique' to 'yes'
         */
        if (this.order.personalize === 'unique' && this.totalGiftcards === 1) {
            this.order.personalize = 'yes';
        }

        if (this.order.personalize === 'yes') {
            this.message = this.order.giftcards[0].message[1];
        }
    },
};
</script>
