<template>
    <div id="shipment" class="container" ref="shipment">
        <div class="content">
            <top-bar @prev-view="$emit('prev-view')" @close-widget="$emit('close-widget')"></top-bar>

            <h1><i class="icon-giftcard branding-color"></i>{{ $t('shipment.title') }}</h1>
            <p>{{ $tc('shipment.subtitle', totalGiftcards) }}</p>

            <div class="form-group">
                <div class="card-group card-arrow">
                    <div class="card" @click="setShipment('email')"
                         :class="{'card-selected': order.shipment === 'email'}">
                        <div class="card-button">
                            <i class="icon-paperplane icon-large branding-color"></i>
                            <p>{{ $t('shipment.method.email') }}</p>
                        </div>
                    </div>
                    <div class="card" @click="setShipment('post')"
                         :class="{'card-selected': order.shipment === 'post'}">
                        <div class="card-button">
                            <i class="icon-shipment icon-large branding-color"></i>
                            <p>{{ $t('shipment.method.mail') }}</p>
                        </div>
                    </div>
                </div>
                <div class="card-subtitle right" v-show="showSubtitle">
                    {{ $t('shipment.helptext', [$money(settings.delivery.discount_threshold)]) }}
                </div>
            </div>

            <!-- Order flow: shipment -->
            <div ref="wrapping" v-show="order.shipment === 'post'" class="wrappings">
                <div
                    v-if="typeof settings.notice.shipment !== undefined
                    && settings.notice.shipment.enabled === true"
                    class="notice-box">
                    {{ settings.notice.shipment.message }}
                </div>

                <h2>{{ $t('shipment.wrappingTitle') }}</h2>
                <div class="form-group">
                    <div class="card-group">
                        <div class="card"
                             v-for="wrapping in settings.wrapping"
                             :key="wrapping.id"
                             @click="setWrapping(wrapping)"
                             :class="{'card-selected': order.wrapping.id === wrapping.id}">
                            <div class="card-button">
                                <img v-if="wrapping.show_giftcard"
                                     :src="$parent.settings.preview_url_small" :alt="$t('card')"
                                     class="wrapping-image-background">
                                <img :src="wrapping.image" :alt="wrapping.name"
                                     class="wrapping-image">
                                <p>{{ wrapping.name }}<br/>
                                    <small>{{ wrapping.price | money}}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref="address" v-if="order.wrapping">
                    <h2>{{ $t('shipment.deliveryTitle') }}</h2>

                    <address-input :address="order.address" @countryUpdated="selectedCountryChanged"/>
                </div>

                <div ref="shipping_options" v-if="order.wrapping">
                    <h2>{{ $t('shipment.shippingTitle') }}</h2>
                    <p>{{ $t('shipment.shippingSubtitle') }}</p>

                    <div class="form-group shipping-cards">
                        <div class="card-group card-list">

                            <div
                                v-for="deliveryOption in deliveryOptions"
                                class="card"
                                 :class="{
                                     'shipping-card-selected branding-border': order.delivery_selected === deliveryOption.method,
                                     'shipping-card-only-option': deliveryOptions.length === 1
                                 }"
                                 @click="setDeliveryMethod(deliveryOption.method)">
                                <div class="card-button shipping-card">
                                    <div class="icon">
                                        <img src="../../../assets/img/postnl.svg" alt="PostNL" v-if="deliveryOption.method === 'standard'">
                                        <img src="../../../assets/img/priority.svg" alt="Priority" v-else-if="deliveryOption.method === 'same-day'">
                                        <img src="../../../assets/img/trackntrace.svg" alt="Track & Trace" v-else>
                                    </div>

                                    <div class="information">
                                        {{ $t('shipment.methods.' + deliveryOption.method) }}<br/>
                                        <span
                                            class="subtitle"
                                            :class="{
                                            'is-green branding-text-success': deliveryOption.method === 'same-day'
                                            }"
                                        >
                                         {{ $t(Vue.prototype.$deliveryEstimate.getDeliveryTimeI18nKey(deliveryCountry, deliveryOption.method )) }}
                                        </span>
                                    </div>

                                    <div class="pricing">
                                        {{ $options.filters.money(shippingCosts(order.shipment, deliveryOption.method)) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Order flow: email -->
            <div ref="email" v-show="order.shipment && (order.shipment === 'post' ? (order.wrapping && order.delivery_selected !== null) : true)">
                <h2 v-if="order.shipment === 'post'">{{ $t('shipment.confirmationTitle') }}</h2>
                <h2 v-else>{{ $t('shipment.emailTitle') }}</h2>

                <p v-if="order.shipment === 'email'">{{ $t('shipment.emailSubtitle') }}</p>
                <p v-else>{{ $t('shipment.addressSubtitle') }}</p>

                <div class="form-group">
                    <div class="input-group">
                        <input id="email" type="email" v-model="order.address.email" required placeholder=" "
                               ref="emailInput">
                        <label for="email">{{ $t('label.email') }}</label>
                    </div>

                    <div class="input-group">
                        <input id="phone" type="tel" v-model="order.phone" required placeholder=" "
                               ref="phoneInput">
                        <label for="phone">{{ $t('label.phone') }}</label>
                    </div>
                </div>
            </div>

            <div v-show="order.shipment  && (order.shipment === 'post' ? order.wrapping : true)">
                <div class="form-group">
                    <div class="checkbox-group">
                        <input id="checkbox" type="checkbox" v-model="order.invoice">
                        <label for="checkbox">{{ $t('checkbox.invoice') }}</label>
                    </div>
                </div>

                <!-- Order flow: invoicing -->
                <div ref="invoice" v-show="order.invoice">
                    <h2>{{ $t('shipment.invoiceTitle') }}</h2>

                    <div class="form-group" v-if="order.shipment === 'post'">
                        <div class="checkbox-group">
                            <input type="checkbox" v-model="copyFromSend" id="checkbox2">
                            <label for="checkbox2">{{ $t('checkbox.copyFromAddress') }}</label>
                        </div>
                    </div>
                    <address-input :address="order.address_invoice"/>
                </div>
            </div>
        </div>

        <next-view-button :disabled="!viewValidates" field="input, textarea" ref="nextViewButton">
            {{ $t('button.proceedCheckout') }}
        </next-view-button>
    </div>
</template>

<script>
import forEach from 'lodash/forEach';
import AddressInput from './AddressInput';
import Vue from 'vue';

export default {
    data() {
        return {
            order: this.$parent.$data.order,
            settings: this.$parent.settings,
            totalGiftcards: this.$parent.totalGiftcards,
            copyFromSend: false,
        };
    },

    components: { AddressInput },

    watch: {
        'order.invoice': function setInvoice(invoice) {
            this.setInvoice(invoice);
        },
        copyFromSend(value) {
            if (value) {
                this.order.address_invoice.company_contact = this.order.address.name;
                this.order.address_invoice.zip = this.order.address.zip;
                this.order.address_invoice.street = this.order.address.street;
                this.order.address_invoice.house_number = this.order.address.house_number;
                this.order.address_invoice.city = this.order.address.city;
                this.order.address_invoice.addition = this.order.address.addition;
                this.order.address_invoice.country = this.order.address.country;
            } else {
                this.order.address_invoice.company_contact = '';
                this.order.address_invoice.zip = '';
                this.order.address_invoice.street = '';
                this.order.address_invoice.house_number = '';
                this.order.address_invoice.city = '';
                this.order.address_invoice.addition = '';
                // We do not empty the country field, as a selection is required select form)
            }
        },
    },

    computed: {
        Vue() {
            return Vue
        },
        deliveryCountry() {
            return typeof this.order.address.country !== 'undefined' ? this.order.address.country : 'NL';
        },
        deliveryOptions() {
            return this.settings.delivery.options[this.deliveryCountry];
        },
        viewValidates() {
            if (typeof this.order !== 'undefined' && typeof this.order.address !== 'undefined' && this.order.shipment === 'post') {
                const address = this.order.address;
                const streetPrefix = address.street.substr(0, 4).toLowerCase();
                const houseNumPrefix = address.house_number.substr(0, 4).toLocaleLowerCase();

                if (streetPrefix === houseNumPrefix) {
                    return false;
                }
            }

            // Validate invoice address
            if (this.order.invoice && !this.validateAddress(this.order.address_invoice)) {
                return false;
            }

            // Validate e-mail
            const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regexEmail.test(this.order.address.email.toLowerCase())) {
                return false;
            }

            // Validate phone nr
            if(this.order.phone.length < 6) {
                return false;
            }

            if (this.order.shipment === 'email') {
                return true;
            } else if (this.order.shipment === 'post') {
                if (this.validateAddress(this.order.address) && this.order.wrapping) {
                    return true;
                }
            }

            return false;
        },
        showSubtitle() {
            let hasLowGiftcards = false;
            const hasSelection = this.order.shipment;

            forEach(this.order.giftcards, (giftcard) => {
                if (giftcard.value < this.settings.delivery.discount_threshold) {
                    hasLowGiftcards = true;
                }
            });

            return !hasSelection && hasLowGiftcards;
        },
    },

    methods: {
        selectedCountryChanged() {
            let hasActiveDeliveryMethod = false;
            const country = typeof this.order.address.country !== 'undefined' ? this.order.address.country
                                                                              : 'NL';

            this.settings.delivery.options[country].forEach((deliveryMethod) => {
                /**
                 * Validate that the newly selected country has the active delivery method,
                 * reverting to 'standard' if not.
                 */
                if (deliveryMethod.method === this.order.delivery_selected) {
                    hasActiveDeliveryMethod = true;
                }
            });

            if (hasActiveDeliveryMethod === false) {
                this.order.delivery_method = this.settings.delivery.options[country][0]['method'];
                this.order.delivery_selected = this.settings.delivery.options[country][0]['method'];
            }
        },

        setDeliveryMethod(method = 'standard') {
            if (method === 'track-n-trace' && this.countrySupportsTrackNTrace === false) {
                return;
            }

            this.order.delivery_selected = method;
            this.order.delivery_method = method;

            this.$nextTick(() => {
                this.$scrollTo(this.$refs.email, 500, {
                    container: this.$parent.$refs.scrollable,
                });
            });
        },

        setShipment(shipment) {
            this.order.shipment = shipment;

            if (shipment === 'email') {
                this.setWrapping(false);
            }

            this.$nextTick(() => {
                let scrollEl;
                if (shipment === 'email') {
                    scrollEl = this.$refs.email;
                } else {
                    scrollEl = this.$refs.wrapping;
                }

                this.$scrollTo(scrollEl, 500, {
                    container: this.$parent.$refs.scrollable,
                });
            });
        },
        setWrapping(wrapping) {
            this.order.wrapping = wrapping;

            setTimeout(() => {
                const scrollEl = this.$refs.address;

                this.$scrollTo(scrollEl, 1500, {
                    container: this.$parent.$refs.scrollable,
                });
            }, 1000);
        },
        setInvoice(invoice) {
            if (!invoice) {
                return;
            }

            this.$nextTick(() => {
                this.$scrollTo(this.$refs.invoice, 500, {
                    container: this.$parent.$refs.scrollable,
                });
            });
        },
        validateAddress(address) {
            // Regex matching Dutch postal codes
            const zipRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

            if (address.country.toUpperCase() === 'NL' && !zipRegex.test(address.zip)) {
                return false;
            }

            // If validated address is invoice address
            if (typeof address.company_contact !== 'undefined') {
                // Require at least the company contact
                if (!address.company_contact) {
                    return false;
                }
            } else if (!address.name && typeof address.company_contact === 'undefined') {
                return false;
            }

            if (!address.house_number || !address.street || !address.city) {
                return false;
            }

            if (address.house_number.length > 10) {
                return false;
            }

            return true;
        },
        shippingCosts(shipment, deliveryMethod = 'standard') {
            return this.$parent.shippingCosts(shipment, deliveryMethod);
        },
    },

    mounted() {
        if (this.$parent.$data.view_ref) {
            this.$nextTick(() => {
                this.$parent.$refs.scrollable.scrollTop = this.$refs[this.$parent.$data.view_ref]
                    .offsetTop;

                this.$parent.$data.view_ref = false;
            });
        }
    },
};
</script>
